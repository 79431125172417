<template>
    <v-card flat>
        <v-card-title class="mb-4">
            <span class="secondary--text font-weight-bold">Add New Snapshot</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <h4 class="secondary--text">Name of the snapshot</h4>
                <v-text-field
                    :disabled="snapshotCreationLoading"
                    label="Provide a snapshot name here"
                    v-model="snapshotName"
                    :rules="[rules.nonEmpty]"
                    required
                    outlined></v-text-field>
                <h4 class="secondary--text mt-4">Description of recently completed work phase</h4>
                <v-textarea
                    v-model="snapshotDescription"
                    label="Provide a description of your snapshot"
                    rows="4"
                    auto-grow
                    :rules="[rules.nonEmpty]"
                    required
                    :disabled="snapshotCreationLoading"
                    outlined></v-textarea>
            </v-form>
        </v-card-text>

        <v-card-actions class="mt-4">
            <div class="d-flex align-center flex-column justify-center w-100">
                <div>
                    <v-btn
                        class="mr-1"
                        :loading="snapshotCreationLoading"
                        :disabled="!valid || snapshotCreationLoading"
                        @click="addNewSnapshot()"
                        color="primary">
                        <v-icon small>add</v-icon>
                        Add Snapshot
                    </v-btn>
                    <v-btn
                        :disabled="snapshotCreationLoading"
                        color="primary"
                        outlined
                        text
                        :to="{
                            name: 'snapshot-overview',
                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                        }">
                        cancel
                    </v-btn>
                </div>
                <div v-if="snapshotCreationError" class="mt-5">
                    <v-alert color="error" icon="warning" text max-width="500">
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Snapshot creation error</span>
                            {{ snapshotCreationErrorContent }}
                        </div>
                    </v-alert>
                </div>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeShortId } from '@/utils'
import { createQuickAndDetailedSnapshots } from '@/mixins/createSnapshot'
import { enumsData } from '@/mixins/enums'
import { snapshotEnums } from '@/mixins/snapshot'

export default {
    mixins: [createQuickAndDetailedSnapshots, enumsData, snapshotEnums],
    data() {
        return {
            snapshotName: '',
            snapshotDescription: '',
            rules: {
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            },
            valid: false
        }
    },
    computed: {
        ...mapGetters('snapshotStore', ['developmentSnapshot'])
    },
    methods: {
        generateShortId(longId) {
            return makeShortId(longId)
        },
        updateShortId(newShortId) {
            this.snapshotShortName = newShortId
        },
        addNewSnapshot: function () {
            const description = this.snapshotDescription
            const longId = this.snapshotName
            const shortId = this.generateShortId(this.snapshotName)
            this.addSnapshot(this.snapshotTypes.DETAILED_SNAPSHOT, longId, shortId, description)
        }
    },
    watch: {
        snapshotName: function (nextValue, prevValue) {
            this.snapshotShortName = this.generateShortId(nextValue)
        }
    }
}
</script>
